import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "../auth";
import Button from "../components/Button";
import TextInput from "../components/TextInput";
import Checkbox from "../components/Checkbox";
import Many2one from "../components/Many2one";
import Selection from "../components/Selection";
import { ModelRef, RegisterErrorObject, RegisterInfo } from "../types";
import useModelRef from "../useModelRef";

const Register: React.FC = () => {
    const auth = useAuth();
    const [companyName, setCompanyName] = useState("");
    const [companyOfficialName, setCompanyOfficialName] = useState("");
    const [vatNo, setVatNo] = useState("");
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [companyCountry, setCompanyCountry] = useState(0);
    const [companySize, setCompanySize] = useState("");
    const [companyType, setCompanyType] = useState("");
    const [terms, setTerms] = useState<boolean>(false);
    const [fieldErrors, setFieldErrors] = useState(new Map<string, string>());
    const [isFormSent, setIsFormSent] = useState<boolean>(false);
    const [countries, setCountries] = useState<Array<ModelRef>>([]);

    const { getModelRef } = useModelRef();
    const { getChoiceRef } = useModelRef();

    const companySizes = getChoiceRef("companySize") || [];
    const companyTypes = getChoiceRef("companyType") || [];

    const handleErrorResponse = useCallback(
        (err: RegisterErrorObject) => {
            const newFieldsWithError = new Map<string, string>();

            if (isFormSent) {
                if (password.length < 8) {
                    newFieldsWithError.set(
                        "password",
                        "La contraseña debe tener al menos 8 caracteres, un número y un caracter especial (?!#$)"
                    );
                } else if (password !== passwordConfirm) {
                    newFieldsWithError.set("passwordConfirm", "La contraseñas no coinciden");
                }

                err.data.company_name && newFieldsWithError.set("companyName", "Este campo no puede estar vacío");
                err.data.company_type && newFieldsWithError.set("companyType", "Selecciona una opción");
                err.data.name && newFieldsWithError.set("name", "Este campo no puede estar vacío");
                if (err.data.email) {
                    if (err.data.email.includes("This email is already taken.")) {
                        newFieldsWithError.set("email", "Este email ya está en uso");
                    } else {
                        newFieldsWithError.set("email", "Introduce un email válido");
                    }
                }
            }

            setFieldErrors(newFieldsWithError);
        },
        [setFieldErrors, isFormSent, password, passwordConfirm]
    );

    const handleSubmit = (ev: React.FormEvent) => {
        ev.preventDefault();

        // Pre-checks before making the register API call
        const newFieldsWithError = new Map<string, string>();
        if (!terms) {
            newFieldsWithError.set("terms", "Debes aceptar nuestros términos y condiciones");
        }
        if (newFieldsWithError.size > 0) {
            setFieldErrors(newFieldsWithError);
        } else {
            // Pre-checks OK, try registering and handle any errors returned by the backend
            auth.register(
                password,
                name,
                surname,
                email,
                phone,
                companyName,
                companyOfficialName,
                vatNo,
                companyCountry,
                companySize,
                companyType
            ).then((err: RegisterInfo) => {
                if (err) {
                    err.error && handleErrorResponse(err.error);
                }
            });
        }
    };

    useEffect(() => {
        if (countries.length === 0) {
            getModelRef("countries").then(
                (retrievedCountries) => retrievedCountries && setCountries(retrievedCountries)
            );
        }
    }, [countries, getModelRef]);
    /* eslint-disable no-useless-escape */
    const emailValidationHandler = (emailString: string) =>
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(emailString) ? setEmail(emailString) : setEmail("");
    /* eslint-disable no-useless-escape */
    const passwordValidationHandler = (pwdString: string) =>
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(pwdString)
            ? setPassword(pwdString)
            : setPassword("");

    return (
        <div className="h-screen w-full  font-sans">
            <div className="absolute top-10 left-8 w-40">
                <img src="/static/mokenapp/logo_grey.png" alt="service logo" />
            </div>
            <div className="flex flex-col w-full lg:flex-row h-full min-h-[900px]">
                <div className="sm:flex sm:flex-col  items-start justify-center w-full pt-14 lg:basis-1/2 bg-blue-light-4 8 px-8 p-24">
                    <p className="font-sans font-bold text-2xl text-blue text-left mt-12">Crear cuenta</p>
                    <p className="font-sans font-thin text-blue">Únete a la revolución Moken.</p>
                    <div className="flex flex-col  sm:flex-row  items-center justify-between mt-8 w-full gap-10 md:gap-4">
                        <div className="w-full sm:basis-1/2">
                            <TextInput
                                id="name"
                                type="text"
                                label="Nombre*"
                                onChange={(ev) => setName(ev.currentTarget.value)}
                                errorMessage={fieldErrors.get("name")}
                            />
                        </div>
                        <div className="w-full sm:basis-1/2">
                            <TextInput
                                id="surname"
                                type="text"
                                label="Apellidos"
                                onChange={(ev) => setSurname(ev.currentTarget.value)}
                                errorMessage={fieldErrors.get("surname")}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col  sm:flex-row  items-center justify-between mt-8 w-full gap-10 md:gap-4">
                        <div className="w-full sm:basis-1/2">
                            <TextInput
                                id="email"
                                type="text"
                                label="E-mail*"
                                onChange={(ev) => emailValidationHandler(ev.currentTarget.value)}
                                errorMessage={fieldErrors.get("email")}
                            />
                        </div>
                        <div className="w-full sm:basis-1/2">
                            <TextInput
                                id="phone"
                                type="number"
                                label="Teléfono"
                                onChange={(ev) => setPhone(ev.currentTarget.value)}
                                errorMessage={fieldErrors.get("phone")}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col sm:flex-row  items-center justify-between mt-8 w-full gap-10 md:gap-4">
                        <div className="w-full sm:basis-1/2">
                            <TextInput
                                id="password"
                                type="password"
                                label="Contraseña*"
                                onChange={(ev) => passwordValidationHandler(ev.currentTarget.value)}
                                errorMessage={fieldErrors.get("password")}
                            />
                        </div>
                        <div className="w-full sm:basis-1/2">
                            <TextInput
                                id="passwordConfirm"
                                type="text"
                                label="Repetir contraseña*"
                                onChange={(ev) => setPasswordConfirm(ev.currentTarget.value)}
                                errorMessage={fieldErrors.get("passwordConfirm")}
                            />
                        </div>
                    </div>

                    <form id="registerform" onSubmit={handleSubmit} className="w-full text-right">
                        <div className="flex flex-col sm:flex-row items-center justify-between mt-8 w-full gap-10 md:gap-4">
                            <div className="w-full sm:basis-1/2">
                                <TextInput
                                    id="companyName"
                                    type="text"
                                    label="Nombre comercial*"
                                    onChange={(ev) => setCompanyName(ev.currentTarget.value)}
                                    errorMessage={fieldErrors.get("companyName")}
                                />
                            </div>
                            <div className="w-full sm:basis-1/2">
                                <Selection
                                    id="companyType"
                                    size="small"
                                    label="Tipo de compañia*"
                                    choices={companyTypes}
                                    onChange={(choice) => setCompanyType(choice.value)}
                                    errorMessage={fieldErrors.get("companyType")}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col  sm:flex-row  items-center justify-between mt-8  w-full gap-10 md:gap-4">
                            <div className="w-full sm:basis-1/2">
                                <TextInput
                                    id="vatNumber"
                                    type="text"
                                    label="CIF/NIF"
                                    onChange={(ev) => setVatNo(ev.currentTarget.value)}
                                    errorMessage={fieldErrors.get("vatNo")}
                                />
                            </div>
                            <div className="w-full sm:basis-1/2">
                                <Many2one
                                    id="companyCountry"
                                    label="País"
                                    data={countries}
                                    onClick={(selected) => setCompanyCountry(selected.id)}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col sm:flex-row  items-center justify-between mt-8 w-full gap-10 md:gap-4">
                            <div className="w-full sm:basis-1/2">
                                <Selection
                                    id="companySize"
                                    size="small"
                                    label="Tamaño de la compañia"
                                    choices={companySizes}
                                    onChange={(choice) => setCompanySize(choice.value)}
                                    errorMessage={fieldErrors.get("companySize")}
                                />
                            </div>
                            <div className="w-full sm:basis-1/2">
                                <TextInput
                                    id="officialName"
                                    type="text"
                                    label="Razón social"
                                    onChange={(ev) => setCompanyOfficialName(ev.currentTarget.value)}
                                    errorMessage={fieldErrors.get("companyOfficialName")}
                                />
                            </div>
                        </div>
                        <div className="text-left mt-10">
                            <Checkbox
                                label={
                                    <p>
                                        Estoy de acuerdo con los
                                        <a
                                            className="text-yellow"
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://themoken.com/privacy.html"
                                        >
                                            {" "}
                                            términos del servicio
                                        </a>{" "}
                                        y la
                                        <a
                                            className="text-yellow"
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://themoken.com/privacy.html"
                                        >
                                            {" "}
                                            política de privacidad
                                        </a>
                                    </p>
                                }
                                onChange={(ev) => setTerms(ev)}
                                errorMessage={fieldErrors.get("terms")}
                            />
                        </div>

                        <hr className="mt-8 pb-4 border-blue-light-3" />
                        <div className="mb-8 flex flex-row justify-end w-full">
                            <Button submit label="Crear cuenta" type="btn_yellow" onClick={() => setIsFormSent(true)} />
                        </div>
                    </form>

                    <p className="flex flex-col justify-center items-center sm:flex-row font-sans self-center text-blue pb-12 sm:pb-0">
                        <span>¿Ya tienes cuenta Moken?</span>
                        <a className="text-yellow hover:text-yellow-dark-2 pl-4 mt-6 sm:mt-0" href="/login">
                            Log in
                        </a>
                    </p>
                </div>
                <div className="hidden lg:flex lg:flex-col items-center justify-center basis-1 pt-12 lg:basis-1/2 lg:pt-0 h-full bg-gradient-to-t from-yellow-dark-2 to-yellow px-8 p-24 ">
                    <img src="/static/mokenapp/login_request.png" alt="requests" />
                    <p className="font-sans text-white font-bold text-2xl mt-12">Solicitudes nuevas o pendientes</p>
                    <p className="font-sans text-white">
                        Accede directamente a tus presupuestos pendientes y evita olvidar tus tareas pendientes.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Register;
